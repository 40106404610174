import Offcanvas from 'react-bootstrap/Offcanvas';
import React, { useEffect, useState } from 'react';
import HeaderSearch from '../community/headerSearch';
import dotsIcon from "../../assets/img/dots.svg"
import deleteIcon from "../../assets/img/products/removeicon.svg";
import { apiUrl } from '../../utils/url';
import { useSelector } from 'react-redux';
import { useAlert } from '../../contexts/alertContext';
import Skeleton from 'react-loading-skeleton';
import { ReusableModal } from '../common/ReusableModal';
import gearIcon from "../../assets/img/gear.svg";
import { hideLoader, showLoader } from '../loader';
import NewConfirmationModal from '../common/new-confirmation-modal';
import settings from "../../assets/img/modals/blue-filled-gear.svg"

const ChatbotSidebar = ({ setEditModal, chats, setChats, activeChat, setChatId }) => {
    let params = [];
    const { slug } = useSelector(state => state?.slugReducer);
    const pro = useSelector(state => state?.proReducer);
    const [loading, setloading] = useState(true);
    const [confirmationModalShow, setconfirmationModalShow] = useState({ show: false, text: null, callback: null });
    const { setAlert } = useAlert();
    const [filter, setFilter] = useState({ page: 1, orderBy: null, filter: null, orderType: "desc", count: 12 });
    const getChats = async () => {
        setloading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/chat/get/${slug}?${params?.join("&")}`, {
            headers: myHeaders
        })
        const response = await res.json();
        setloading(false);
        if (response?.status_code == 200) {
            setChats(response?.data?.chats);
        } else {
            setAlert({ type: 'success', title: response?.status_message })
        }

    }
    useEffect(() => {
        if (!pro) {
            // 
        } else {
            // 
            params = [];
            if (slug) {
                for (const key in filter) {
                    if (Object.hasOwnProperty.call(filter, key))
                        if (filter[key]) params.push(`${[key]}=${filter[key]}`)
                };
                if (filter?.page) getChats()
            }
        }
    }, [slug, filter, pro]);
    const deleteChat = async (id) => {
        if (!id) {
            return;
        }
        showLoader()
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/chat/delete-thread/${id}`, {
            method: 'DELETE',
            headers: myHeaders
        })
        const response = await res.json();
        if (response?.status_code == 200) {
            setChats((prevData) => ({
                history_segments: prevData?.history_segments.map((segment) => ({
                    ...segment,
                    chats: segment.chats.filter(chat => chat.id !== id)
                })),
            }));
            if (activeChat && id === activeChat) {
                setChatId(null);
            }
            hideLoader()
            setAlert({ type: 'success', title: response?.status_message });
        } else {
            hideLoader()
            setAlert({ type: 'error', title: response?.status_message });
        }
    }
    const clearHistory = async () => {
        setconfirmationModalShow({ show: false });
        return;
        showLoader()
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/chat/clear-history/${slug}`, {
            method: 'DELETE',
            headers: myHeaders
        })
        const response = await res.json();
        if (response?.status_code == 200) {
            setChats([]);
            setChatId(null);
            hideLoader()
            setAlert({ type: 'success', title: response?.status_message });
        } else {
            hideLoader()
            setAlert({ type: 'error', title: response?.status_message });
        }
    }
    const clear = () => {
        setconfirmationModalShow({
            show: true,
            icon: settings,
            title: 'Clear History',
            message: 'Are you sure that you want to clear your history?',
            buttonText: 'Yes, Delete',
            callback: clearHistory,
        });
    };
    return (
        <>
            <div className={`offcanvas offcanvas-end`} id="chatbotModal" tabIndex="-1" aria-labelledby="chatbotModalExampleLabel">
                <div className='offcanvas-body chat-sidebar-main'>
                    <div className='chat-options'>
                        <div className='cursor-pointer chat-back-btn d-none align-items-center gap-1' data-bs-dismiss="offcanvas">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                                <path d="M13.5352 6.99944L0.999442 6.99944M0.999442 6.99944L5.70033 12.7852M0.999442 6.99944L5.70033 1.21373" stroke="#6F6E7A" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span className='font-weight-500 fs-14px text-gray'>Back</span>
                        </div>
                        <button className='blue-btn' onClick={() => setChatId(null)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.41797 2.3331H3.96797C2.98787 2.3331 2.49787 2.3331 2.12347 2.5239C1.79417 2.6917 1.52647 2.9594 1.35867 3.2887C1.16797 3.663 1.16797 4.153 1.16797 5.1331V10.0331C1.16797 11.0132 1.16797 11.5033 1.35867 11.8776C1.52647 12.2069 1.79417 12.4746 2.12347 12.6424C2.49787 12.8331 2.98787 12.8331 3.96797 12.8331H8.86797C9.84807 12.8331 10.3381 12.8331 10.7125 12.6424C11.0418 12.4746 11.3095 12.2069 11.4773 11.8776C11.668 11.5033 11.668 11.0132 11.668 10.0331V7.5831M4.66797 9.3331H5.64477C5.93007 9.3331 6.07277 9.3331 6.20707 9.3009C6.32607 9.2723 6.43987 9.2252 6.54427 9.1612C6.66207 9.0891 6.76287 8.9882 6.96467 8.7864L12.543 3.2081C13.0262 2.7249 13.0262 1.9414 12.543 1.4581C12.0597 0.974903 11.2762 0.974903 10.793 1.4581L5.21467 7.0364C5.01287 7.2382 4.91197 7.3391 4.83987 7.4568C4.77587 7.5612 4.72877 7.675 4.70017 7.794C4.66797 7.9283 4.66797 8.071 4.66797 8.3563V9.3331Z" stroke="white" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <span className='text-nowrap'>
                                New Chat
                            </span>
                        </button>
                        <HeaderSearch filter={filter} name={'chats'} setFilter={setFilter} />
                        <button className='bg-f3f3f3 disconnect-domain-btn' id="history-more-options" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={dotsIcon} alt="" srcset="" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="history-more-options">
                            <li>
                                <a onClick={clear} className="dropdown-item gray-hover justify-content-start gap-2">
                                    <img src={deleteIcon} width={16} height={18} alt="" srcset="" className="filter-brightness-0" />
                                    <span className="me-2">Clear History</span>
                                </a>
                            </li>
                            <li>
                                <a href={"https://www.zaap.ai/support"} target="_blank" className="dropdown-item gray-hover justify-content-start gap-2 black-svg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.58881 0.896089C6.70516 -0.759522 9.29048 0.0306886 9.29048 2.0275V9.68942C9.29048 10.8071 8.38442 11.7132 7.26674 11.7132H2.10042C0.47924 11.7132 -0.48386 9.90217 0.422484 8.55801L5.58881 0.896089ZM7.10534 9.36662L7.10534 3.0836C7.10534 2.92435 6.89916 2.86133 6.81013 2.99336L2.57357 9.27639C2.50129 9.38359 2.5781 9.52802 2.70739 9.52802H6.94395C7.03308 9.52802 7.10534 9.45576 7.10534 9.36662ZM9.74289 16.8863C8.62653 18.5419 6.04121 17.7517 6.04121 15.7549L6.04121 13.6636C6.04121 13.0602 6.53037 12.5711 7.13378 12.5711C7.73719 12.5711 8.22635 13.0602 8.22635 13.6636V14.6988C8.22635 14.8581 8.43254 14.9211 8.52157 14.789L12.7581 8.50602C12.8304 8.39882 12.7536 8.25439 12.6243 8.25439H11.0764C10.473 8.25439 9.98385 7.76523 9.98385 7.16182C9.98385 6.55841 10.473 6.06925 11.0764 6.06925H13.2313C14.8525 6.06925 15.8156 7.88025 14.9092 9.2244L9.74289 16.8863Z" fill="#333333" />
                                    </svg>
                                    <span className="me-2">Contact Our Team</span>
                                </a>
                            </li>
                        </ul>

                    </div>
                    <div className="chat-history">
                        {loading ?
                            <>
                                <div className='chat-cont chat-time'>Today</div>
                                {Array(5).fill(null).map((idx) => (

                                    <div className='chat-cont chat-title-cont'>
                                        <Skeleton height={15} width={312} />
                                        <Skeleton width={24} height={15} />
                                    </div>
                                ))
                                }
                            </>
                            :
                            !chats || !chats?.history_segments || chats?.history_segments?.length === 0 ?
                                <>
                                    <div className='m-auto fs-14px font-weight-500'>
                                        No Chats Found
                                    </div>
                                </>
                                :
                                chats?.history_segments?.map((val, index) => (
                                    <>
                                        <div className='chat-cont chat-time'>{val?.label}</div>
                                        {
                                            val?.chats?.map((chat, index) => (
                                                <>
                                                    <div className={`chat-cont chat-title-cont ${chat?.id == activeChat && 'active'}`} onClick={() => { chat?.id !== activeChat && setChatId(chat?.id) }}>
                                                        <div className='chat-title text-truncate'>
                                                            {chat?.title}
                                                        </div>
                                                        <div className="chat-more-options cursor-pointer" onClick={(e) => e.stopPropagation()} data-bs-toggle="dropdown" aria-expanded="false">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#333333" stroke-opacity="0.41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#333333" stroke-opacity="0.41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#333333" stroke-opacity="0.41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                        <ul className="dropdown-menu" aria-labelledby="chat-more-options">
                                                            <li>
                                                                <a onClick={(e) => { e.stopPropagation(); setEditModal({ show: true, ...chat }) }} data-bs-dismiss="offcanvas" aria-label="Close" className="dropdown-item gray-hover justify-content-start gap-2">
                                                                    <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.41797 2.3331H3.96797C2.98787 2.3331 2.49787 2.3331 2.12347 2.5239C1.79417 2.6917 1.52647 2.9594 1.35867 3.2887C1.16797 3.663 1.16797 4.153 1.16797 5.1331V10.0331C1.16797 11.0132 1.16797 11.5033 1.35867 11.8776C1.52647 12.2069 1.79417 12.4746 2.12347 12.6424C2.49787 12.8331 2.98787 12.8331 3.96797 12.8331H8.86797C9.84807 12.8331 10.3381 12.8331 10.7125 12.6424C11.0418 12.4746 11.3095 12.2069 11.4773 11.8776C11.668 11.5033 11.668 11.0132 11.668 10.0331V7.5831M4.66797 9.3331H5.64477C5.93007 9.3331 6.07277 9.3331 6.20707 9.3009C6.32607 9.2723 6.43987 9.2252 6.54427 9.1612C6.66207 9.0891 6.76287 8.9882 6.96467 8.7864L12.543 3.2081C13.0262 2.7249 13.0262 1.9414 12.543 1.4581C12.0597 0.974903 11.2762 0.974903 10.793 1.4581L5.21467 7.0364C5.01287 7.2382 4.91197 7.3391 4.83987 7.4568C4.77587 7.5612 4.72877 7.675 4.70017 7.794C4.66797 7.9283 4.66797 8.071 4.66797 8.3563V9.3331Z" stroke="#333333" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    <span className="me-2">Edit Title</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a onClick={(e) => { e.stopPropagation(); deleteChat(chat?.id) }} className="dropdown-item gray-hover justify-content-start gap-2">
                                                                    <img src={deleteIcon} width={16} height={14} alt="" srcset="" className="filter-brightness-0" />
                                                                    <span className="me-2">Delete Chat</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </>
                                ))
                        }
                    </div>
                    <div style={{padding:20}} className='d-flex justify-content-between align-items-center gap-2'>
                        <div className='fs-12px text-gray'>
                            {chats?.pagination?.fetched_records} Results
                        </div>
                        {
                            filter?.page && (
                                <div class="d-flex align-items-center gap-1">
                                    <button className={`bg-f3f3f3 disconnect-domain-btn ${chats?.pagination?.current_page == 1 ? 'disabled' : ''}`} onClick={() => setFilter({ ...filter, page: filter?.page - 1 })} disabled={loading ? true : (chats?.pagination?.current_page == 1 ? true : false)}>Previous</button>

                                    {filter?.page !== 1 && <button disabled={loading} onClick={() => setFilter({ ...filter, page: filter?.page - 1 })} class="bg-f3f3f3 disconnect-domain-btn">{filter?.page - 1}</button>}

                                    <button onClick={() => setFilter({ ...filter, page: filter?.page })} class="bg-dark text-light disconnect-domain-btn">{filter?.page}</button>

                                    <button onClick={() => setFilter({ ...filter, page: filter?.page + 1 })} class="bg-f3f3f3 disconnect-domain-btn" disabled={loading ? true : (filter?.page == chats?.pagination?.last_page)}>{filter?.page + 1}</button>

                                    {filter?.page == 1 && <button onClick={() => setFilter({ ...filter, page: filter?.page + 2 })} disabled={loading ? true : (filter?.page + 2 > chats?.pagination?.last_page)} class="bg-f3f3f3 disconnect-domain-btn">{filter?.page + 2}</button>}

                                    <button onClick={() => setFilter({ ...filter, page: filter?.page + 1 })} disabled={loading ? true : (filter?.page == chats?.pagination?.last_page)} className={`bg-f3f3f3 disconnect-domain-btn ${filter?.page == chats?.pagination?.last_page ? 'disabled' : ''}`}>Next</button>
                                </div>

                            )
                        }
                    </div>
                </div>
            <NewConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setconfirmationModalShow} callback={confirmationModalShow?.callback} />
            </div>
        </>
    );
};

export default ChatbotSidebar;