import React from 'react'
import CustomDropdown from '../common/customDropdown'
import CustomTooltip from '../common/CustomTooltip'
import infoIcon from "../../assets/img/info-black.svg"

const DesignComponents = ({dropDownOptions, handleSelect, desc, label, defaultOption = null, reducerKey=null, dropdownLabel="label", valueKey="value"}) => {
    return (
        <div className='design-components d-flex align-items-start flex-column gap-10px'>
            <div className='d-flex align-items-center gap-1'>

                <div className='theme-type-div-heading'>
                    {label}
                </div>
                <CustomTooltip contentClass="p-2" customClass="mb-1" message={[{msg: desc}]}>
                    <img src={infoIcon} alt="" className='cursor-pointer' />
                </CustomTooltip>
            </div>
            <div className='d-flex justify-content-between align-center-center w-100 gap-2'>
                <CustomDropdown
                    options={dropDownOptions}
                    defaultOption={defaultOption}
                    onSelect={handleSelect}
                    reducerKey={reducerKey}
                    dropdownLabel={dropdownLabel}
                    valueKey={valueKey}
                />
                <span className='btn-ededed fs-12px '>
                    {desc}
                </span>

            </div>
        </div>
    )
}

export default DesignComponents