import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../common/customDropdown';
import trashIcon from "../../assets/img/page/trash.svg";
import plusIcon from "../../assets/img/thin-plus-icon.svg";
import ImageCropper from '../croppie-main';
import Socials from './socials';
const Options = ({ handleFormChanges, showSaveChanges, setIframeURL, setIframeIndex }) => {
    const value = useSelector(state => state?.customizeSeoReducer);
    const [seoformState, setSeoFormState] = useState({ ...value });
    const [showCroppieModal, setShowCroppieModal] = useState({ show: false });

    const dispatch = useDispatch();
    const deleteImage = () => dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, "image": null } })

    const handleChange = (e) => {
        if (e.target.name == "block_indexing") {
            dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, [e.target.name]: e.target.checked ? 1 : 0 } })
        }
        else dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, [e.target.name]: e.target.value } })
    }
    useEffect(() => {
        handleFormChanges('seoValue', value, seoformState)
    }, [value]);
    useEffect(() => {
        if (!showSaveChanges?.show) {
            setSeoFormState({ ...value });
        }
    }, [showSaveChanges?.show])
    const handleDropdownChange = (val, key) => {
        dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, [key]: val } })
    };
    const handleImageCropped = ({ blob, imageUrl }) => {
        dispatch({ type: "SET_CUSTOMIZE_SEO", payload: { ...value, "image": imageUrl, "imageData": blob } });
    }
    const showCroppie = (event) => {
        setShowCroppieModal({ show: true, event: event });
    };
    // console.log("seovalue", value)
    return (
        <div className='blocks-content new-page-editor-section scrollbar-on-hover'>
            <div className='page-editor-heading'>
                SEO
            </div>
            <div className='d-flex gap-30px flex-column'>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Tilte</div>
                        <div className='details-inp-desc'>The title of your page</div>
                    </div>
                    <div className='second-half'>
                        <input type="text" className="project-new-inp" placeholder="Use a name that your audience will instantly recognise." name="page_title" onChange={handleChange} value={value?.page_title} />
                    </div>
                </div>
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Description</div>
                        <div className='details-inp-desc'>Give a short description for your page</div>
                    </div>
                    <div className='second-half'>
                        <div className='project-new-inp'>

                            <textarea className='transparent-textarea p-0' name="page_description" onChange={handleChange} placeholder='Use a description that your audience will instantly recognise, like your company description.' value={value?.page_description} id=""></textarea>
                            <div className='fs-10px text-end'>
                                <span>
                                    {value?.page_description?.length}
                                </span>
                                <span className='text-grey'>
                                    /225
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Username</div>
                        <div className='details-inp-desc'>The url name of your page</div>
                    </div>
                    <div className='second-half'>
                        <div className='d-flex align-items-center project-new-inp'>
                            <span className='text-gray'>
                                zaap.bio/
                            </span>
                            <input className='p-0 fs-12px' type="text" name="slug" onChange={handleChange} placeholder="Page Slug" value={value?.title} />
                        </div>
                    </div>
                </div> */}
                <div className='details-form-group'>
                    <div className='first-half'>
                        <div className='details-inp-label'>Search Engine Indexing</div>
                        <div className='details-inp-desc'>Choose if you want search engines to index your page.</div>
                    </div>
                    <div className='second-half'>
                        <CustomDropdown onSelect={handleDropdownChange} valueKey="value" reducerKey="block_indexing" dropdownLabel="label" options={[{ label: 'Enabled', value: 1 }, { label: 'Disabled', value: 0 }]} defaultOption={value?.block_indexing == 1 ? 'Enabled' : 'Disabled'} />
                    </div>
                </div>
                <div>
                    <div className='details-inp-label'>
                        Opengraph Image
                    </div>
                    {value?.image ?
                        <div className='mt-20px d-flex justify-content-start gap-20px align-items-center'>
                            <img className="design-banner-image" src={value?.image} alt="" />
                            <div className='d-flex align-items-center gap-10px'>
                                <label htmlFor="img_logo" className="cursor-pointer upload--profile-btn borderless position-relative">
                                    <img className='filter-invert-1 cursor-pointer' width={12} src={plusIcon} alt="" />
                                    Upload Image
                                    <input id="img_logo" name='img_logo' type="file" onChange={showCroppie} className='d-none' />
                                </label>
                                <button type='button' onClick={deleteImage} className="upload--profile-btn borderless">
                                    <img src={trashIcon} width={15} alt="" />
                                </button>
                            </div>
                        </div>
                        :
                        <label htmlFor='img_logo' className='upload-banner cursor-pointer'>
                            <input id='img_logo' type="file" accept=".png,.jpg,.jpeg" name="img_logo"
                                className={`d-none`} onChange={showCroppie} />
                            <div>
                                Upload Image
                            </div>
                            <button type='button' className="upload--profile-btn borderless">
                                <img className='filter-invert-1' width={12} src={plusIcon} alt="" />
                            </button>
                        </label>
                    }
                </div>

            </div>
            <div className="design-content-divider mb-1"></div>

            <Socials setIframeURL={setIframeURL} setIframeIndex={setIframeIndex}/>
            <ImageCropper viewportType={"rectangle"} boundaryWidth={400} boundaryHeight={234} viewportWidth={380} viewportHeight={214} outputQuality={1} onImageCropped={handleImageCropped} showCroppieModal={showCroppieModal} setShowCroppieModal={setShowCroppieModal} />
        </div>
    )
}

export default Options