import React, { Component } from "react";
import iro from "@jaames/iro";
import { hideLoader, showLoader } from "../loader";

export const handleSubmitCustomTheme = (actionType, dispatch, prevState, themeType) => {
  showLoader();
  console.log("$('#rgb_code-' + themeType).val()",$("#rgb_code-" + themeType).val());
  let existingTheme = prevState.custom_theme
    ? JSON.parse(prevState.custom_theme)
    : {};
    let updatedTheme = {
      ...existingTheme, 
      [themeType]: $("#rgb_code-" + themeType).val() || '#ffffff', 
    };
  dispatch({
    type: actionType,
    payload: {
      ...prevState,
      custom_theme: JSON.stringify(updatedTheme),
    },
  });
  hideLoader();

  setTimeout(() => {
    return;
  }, 50000);
};

class IroColorPicker extends React.Component {
  typingTimer; //timer identifier
  doneTypingInterval = 500;
  componentDidMount() {
    const { props } = this;
    console.log(props + "props data");
    const _this = this;
    // create a new iro color picker and pass component props to it
    this.colorPicker = new iro.ColorPicker(this.el, {
      ...props,
      display:"block",
      handleSvg: '#pickerCircle',
      handleProps: { x: 0, y: -10 },
      layout: [
        { 
          component: iro.ui.Box,
          options: {}
        },{
          component: iro.ui.Slider,
          options: {
            id: 'hue-slider',
            sliderType: 'hue',
          }
        }
      ]
    });
    const sliderElement = this.el.querySelector('.IroSlider');
    if (sliderElement) {
      sliderElement.style.height = '10px';
    }
//     const irosliderParent = document.querySelector('.IroColorPicker > .IroBox > svg');

//     if (irosliderParent) {       
//           const useElement = irosliderParent.getElementById('pickerCircle');
// console.log("useElement",)
//           useElement.stroke = 'white';
//           }
    
    // call onColorChange prop whenever the color changes
    this.colorPicker.on("color:change", (color) => {
      if (
        props?.themeType.includes("header") ||
        props?.themeType.includes("background") ||
        props?.themeType.includes("links") ||
        props.themeType?.includes("button_text")
      ) {
        $(`#hex_code-${props?.themeType}`).val(color.hexString);
        $(`.custom-theme-preview svg ${props.nthChild}`).css(
          "fill",
          $(`#hex_code-${props?.themeType}`).val()
        );

        $(`#rgb_code-${props?.themeType}`).val(color.hexString);
      } else if (props?.themeType.includes("buttons")) {
        $(`#hex_code-${props?.themeType}`).val(color.hexString);
        $(`.custom-theme-preview svg path:nth-child(5)`).css(
          "fill",
          $(`#hex_code-${props?.themeType}`).val()
        );
        $(`.custom-theme-preview svg path:nth-child(4)`).css(
          "fill",
          $(`#hex_code-${props?.themeType}`).val()
        );

        $(`#rgb_code-${props?.themeType}`).val(color.hexString);
      }
    });

    $(`#hex_code-${props?.themeType}`).keyup(function () {
      clearTimeout(_this.typingTimer);
      try {
        if ($(this).val().length > 5) {
            _this.typingTimer =_this.colorPicker.color.hexString = $(this).val();
        }
      } catch {}
    });
  }

  componentDidUpdate() {
    // isolate color from the rest of the props
    const { color, ...colorPickerState } = this.props;
    // update color
    try {
      if (color) this.colorPicker.color.set(color);
      this.colorPicker.setState(colorPickerState);
    } catch {}
    // push rest of the component props to the colorPicker's state
  }

  render() {
    return (
      <>
      <svg style={{display:"none"}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g id="pickerCircle">
          <circle fill="none" cx="9" cy="9" r="7" stroke="#2C2B2B" stroke-width="3"/>
        </g>
      </svg>
      <div
        className="d-flex flex-column color-picker-main d-none"
        id="iro-picker-container"
      >
        <div ref={(el) => (this.el = el)} />
        <input
          type="text"
          defaultValue={this.props?.color}
          name=""
          id={`hex_code-${this?.props?.themeType}`}
          className="colorinp-u"
          style={{ borderWidth: "1px" }}
        />
        <input
          type="hidden"
          id={`rgb_code-${this?.props?.themeType}`}
          class=""
          value={this?.props?.color}
          name="rgb_code"
          required="required"
        />
        
      </div>
      </>
    );
  }
}
export default IroColorPicker;
