import { Modal } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import returnIcon from "../../../assets/img/modals/return.svg"
import { hideLoader, showLoader } from "../../loader"
import { mainPath } from "../../../pages/page-editor"
import CreateFolderModal from "./sub-modals/createFolderModal"
import CreateLinkModal from "./sub-modals/createLinkModal"
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify';
import CreateEmailWidgetModal from "./sub-modals/createEmailWidgetModal"
import CreateProductWidgetModal from "./sub-modals/createProductWidgetModal"
import CreateYoutubeWidgetModal from "./sub-modals/createYoutubeWidgetModal";
import CreateTextBlockModal from "./sub-modals/createTextBlockModal";
import CreateTiktokWidgetModal from "./sub-modals/createTiktokWidgetModal";
import CreateTwitterWidgetModal from "./sub-modals/createTwitterWidgetModal";
import getProjects from "../../../services/getProjects"
import { apiUrl } from "../../../utils/url"
import CreateEmbedBlockModal from "./sub-modals/createEmbedBlockModal"
import CreateContactModal from "./sub-modals/createContactModal."
import CreateMeetingProductWidgetModal from "./sub-modals/createMeetingProductWidgetModal"
import CreateInstagramWidgetModal from "./sub-modals/createInstagramWidgetModal"
import CreateZaapPostsModal from "./sub-modals/createZaapPostsModal"
import CreateLeadMagnetWidgetModal from "./sub-modals/createLeadMagnetWidgetModal"
import CreateSpotifySyncBlockModal from "./sub-modals/createSpotifySyncWidgetModal"
import { useAlert } from "../../../contexts/alertContext"
import CreateSpotifyWidgetModal from "./sub-modals/createSpotifyWidgetModal"
const SubModals = ({ handleHide, handleClose, show, setShow, setIframeURL, setconfirmationLinkModal }) => {

    const dispatch = useDispatch()
    const { subPath, id } = useParams();
    const history = useHistory();
    const { slug, basePath } = useSelector(state => state?.slugReducer);
    const { setAlert } = useAlert();

    const [error, setError] = useState([]);



    const showModal = (paramsName) => {
        showLoader()
        history.push({
            pathname: `${basePath}/${mainPath}/${paramsName}`,
            state: { showChild: true }
        })
        hideLoader()
    }
    const callGetProject = () => {
        getProjects(slug).then((response) => {
            dispatch({ type: "SET_BLOCK_SETTING", payload: response?.blocks });
            hideLoader()
        }).catch((err) => hideLoader())
    }
    const handleSubmit = (e, values, blockName) => {
        showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        let formValues = {}
        
// console.log("Block Name", blockName)
        if (blockName == "twitter") {
            if (values?.connect == 1) {
                formValues = {
                    block: blockName,
                    project: slug,
                    connect: 1
                }
                const valueToStore = { ...values, ...formValues }
                localStorage.setItem("last_activity", location.pathname)
                localStorage.setItem("twitter_values", JSON.stringify(valueToStore))
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                formValues = values
                delete formValues["connect"];
                localStorage.removeItem("twitter_values")
            }
        }
        else if (blockName == "tiktok") {
            if (values?.connect == 1) {
                formValues = { block: blockName, project: slug, connect: 1 };
                const valueToStore = { ...values, ...formValues };
                localStorage.setItem("last_activity", location.pathname)
                localStorage.setItem("tiktok_values", JSON.stringify(valueToStore));
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                formValues = values;
                delete formValues["connect"];
                // localStorage.removeItem("tiktok_values");
            }
        }else if (blockName == "instagram") {
            if (values?.connect == 1) {
                formValues = { block: blockName, project: slug, connect: 1 };
                const valueToStore = { ...values, ...formValues };
                localStorage.setItem("last_activity", location.pathname)
                localStorage.setItem("instagram_values", JSON.stringify(valueToStore));
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                formValues = values;
                delete formValues["connect"];
                // localStorage.removeItem("tiktok_values");
            }
        }else if (blockName == "spotify") {
            if (values?.connect == 1) {
                formValues = { block: blockName, project: slug, connect: 1 };
                const valueToStore = { ...values, ...formValues };
                localStorage.setItem("last_activity", location.pathname)
                localStorage.setItem("spotify_values", JSON.stringify(valueToStore));
            }
            else if (values?.connect == 0 && values?.callback == 1) {
                formValues = values;
                delete formValues["connect"];
                // localStorage.removeItem("tiktok_values");
            }
        }
        else formValues = { block: blockName, project: slug, ...values };

        const errorList = [];
        const formdata = new FormData();
        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/project/block/create", requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (blockName !== "twitter" && blockName !== "tiktok" && blockName !== "instagram" && blockName !== 'spotify') {
                    if (response?.status_code == 200) {
                        setIframeURL("")
                        if(blockName == 'youtube' || blockName == 'spotify'){
                            setAlert({ type: 'warning', title: `${blockName} Sync Block`, description: "Posts will be updated once every 12hrs" }) 

                        }else {
                            setAlert({ type: 'success', title: `Block Added` });
                            // toast.success(response?.status_message);
                        }
                        if (blockName == "link") {
                            dispatch({
                                type: "SET_EDIT_LINK_MODAL", payload: {
                                    show: true,
                                    id: response?.data?.id,
                                    link_title: response?.data?.name,
                                    destination_url: JSON?.parse(response?.data?.details?.meta)?.destination_url,
                                    link_description: JSON?.parse(response?.data?.details?.meta)?.link_description,
                                    open_in_same_tab: JSON?.parse(response?.data?.details?.meta)?.open_in_same_tab,
                                    button_text: JSON?.parse(response?.data?.details?.meta)?.button_text,
                                    image_url: JSON?.parse(response?.data?.details?.meta)?.link_image,
                                }
                            })
                        }

                        callGetProject()
                        setTimeout(() => {
                            hideLoader()
                            handleHide()
                        }, 1000);
                    }
                    else {
                        if (formValues?.open_in_same_tab) {
                            setError(Object.values(response?.errors))

                        }
                        else {
                            response?.errors?.product?.map((val) => errorList?.push(val));
                            response?.errors?.embed_type?.map((val) => errorList?.push(val));
                            response?.errors?.name?.map((val) => errorList?.push(val));
                            response?.errors?.link_title?.map((val) => errorList?.push(val));
                            response?.errors?.destination_url?.map((val) => errorList?.push(val));
                            response?.errors?.title?.map((val) => errorList?.push(val));
                            response?.errors?.channel_url?.map((val) => errorList?.push(val));
                            response?.errors?.no_of_videos?.map((val) => errorList?.push(val));
                            response?.errors?.text?.map((val) => errorList?.push(val));
                            response?.errors?.alignment?.map((val) => errorList?.push(val));
                            response?.errors?.file?.map((val) => errorList?.push(val));
                            response?.errors?.image?.map((val) => errorList?.push(val));
                        }
                        
// console.log("Response", response)
                        setError(errorList)
                        
// console.log("Error List", errorList.length)
                        
                        if (errorList.length !== 0) {
                            
// console.log("Error List", errorList.length)
                            errorList?.map((err) => {
                                return (
                                    setAlert({ type: 'error', title: response?.status_message})
                                    // toast.error(err)
                                );
                            });
                        }
                        else {
                            setAlert({ type: 'error', title: response?.status_message})
                            // toast.error(response?.status_message)
                        }
                        hideLoader()

                    }
                }
                else {
                    if (response?.status_code == 200) {
                        setAlert({ type: 'warning', title: `${blockName} Sync Block`, description: "Posts will be updated once every 12hrs" }) 
                        if (values?.connect == 1) {
                            location.href = response?.data?.oauth_url
                            // const popup_window = window.open(
                            //     // `http://localhost:3000/tiktok-calback?code=n3gNWsWkYP_3f3YpazT3g45FaDfyf0p_pFuZnwripvXMZgnMLS4ryJHUr0XhEb7dtrZcC514QV03KjBHBBzsyAuWct0YAvZtbrhJthhdxT0%2A3%215871&scopes=user.info.basic%2Cvideo.list&state=1666709441423`
                            //     response?.data?.oauth_url
                            //     ,
                            //     'targetWindow',
                            //     `toolbar=no,
                            //        location=no,
                            //        status=no,
                            //        menubar=no,
                            //        scrollbars=yes,
                            //        resizable=yes,
                            //        width=SomeSize,
                            //        height=SomeSize`)

                            // const checkChild = () => {
                            //     if (popup_window.closed) {
                            //         hideLoader()
                            //     }
                            // }
                            // var timer = setInterval(checkChild, 500);
                        }
                        else {
                            callGetProject()
                            setTimeout(() => {
                                hideLoader()
                                handleHide()
                            }, 1000);

                        }
                    }
                    else {
                        
// console.log(response)
                        toast.error(response?.status_message)
                        hideLoader()
                    }
                }
            }).catch((err) => {
                hideLoader()
            })
    }

    if (subPath == "createFolderModal") {
        setShow(true)
        return (
            <CreateFolderModal
                slug={slug} show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    }
    else if (subPath == "createLinkModal") {

        return (
            <CreateLinkModal history={history} basePath={basePath} mainPath={mainPath} show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} from={"blockEditor"} setshowConfirmation={setconfirmationLinkModal} slug={slug} />
        )
    }
    else if (subPath == "createEmailWidgetModal") {
        return (
            <CreateEmailWidgetModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createProductWidgetModal") {
        return (
            <CreateProductWidgetModal slug={slug}
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createMeetingProductWidgetModal") {
        return (
            <CreateMeetingProductWidgetModal slug={slug}
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createYoutubeWidgetModal") {

        return (
            <CreateYoutubeWidgetModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createTiktokWidgetModal") {

        return (
            <CreateTiktokWidgetModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    }
    else if (subPath == "createInstagramWidgetModal") {

        return (
            <CreateInstagramWidgetModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createTwitterWidgetModal") {

        return (
            <CreateTwitterWidgetModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createTextBlockModal") {

        return (
            <CreateTextBlockModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createEmbedBlockModal") {

        return (
            <CreateEmbedBlockModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createContactModal") {

        return (
            <CreateContactModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createZaapPostsModal") {

        return (
            <CreateZaapPostsModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createSpotifySyncBlockModal") {

        return (
            <CreateSpotifySyncBlockModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createLeadMagnetWidgetModal") {

        return (
            <CreateLeadMagnetWidgetModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createSpotifySyncBlockModal") {

        return (
            <CreateSpotifySyncBlockModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    } else if (subPath == "createSpotifyBlockModal") {

        return (
            <CreateSpotifyWidgetModal
                show={show} handleHide={handleHide} returnIcon={returnIcon} handleClose={handleClose} handleSubmit={handleSubmit} error={error} />
        )
    }

}
export default SubModals