import React, { useEffect, useState } from 'react'
import PageHeader from '../components/page-header'
import NewSideBar from '../components/side-bar/new-sidebar'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Content from '../components/new-page-editor/content';
import { hideLoader } from '../components/loader';
import { apiUrl, pageUrl, protocol } from '../utils/url';
import { useDispatch, useSelector } from 'react-redux';
import Design from '../components/new-page-editor/design';
import { toast } from 'react-toastify';
import { useAlert } from '../contexts/alertContext';
import getProjects from '../services/getProjects';
import Profile from '../components/new-page-editor/profile';
import Teammoadal from '../components/teammember/team_modal';
import ProfilePicUploadModal from '../components/customize-picture-upload-modal';
import UploadImageBanner from '../components/page-editor/block-editor/sub-modals/uploadImageBanner';
import ErrorMessage from '../components/errors/errorMessage';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Skeleton from 'react-loading-skeleton';
import Options from '../components/new-page-editor/options';
import SaveChanges from '../components/new-page-editor/save-changes';
import { debounce } from "lodash";

const RELATED_FIELDS_MAP = {
    banner_group: ['profile_style', 'editLink', 'banner', 'selected'],
    theme: ['theme', 'selectedTheme', 'custom_theme'],
};

// Reverse mapping to quickly find which group a field belongs to
const getFieldGroupMapping = () => {
    const mapping = {};
    Object.entries(RELATED_FIELDS_MAP).forEach(([groupName, fields]) => {
        fields.forEach(field => {
            mapping[field] = groupName;
        });
    });
    return mapping;
};

export const getChangedFields = (currentValue, initialValue) => {
    const changes = [];
    const fieldToGroup = getFieldGroupMapping();
    const processedGroups = new Set();

    const compareObjects = (current, initial, path = '') => {
        Object.keys(current || {}).forEach(key => {
            const currentVal = current[key];
            const initialVal = initial[key];
            const fullPath = path ? `${path}.${key}` : key;
            
            // Check if this field belongs to a group
            const groupName = fieldToGroup[key];
            if (groupName && !processedGroups.has(groupName)) {
                // Process entire group at once
                processedGroups.add(groupName);
                const groupFields = RELATED_FIELDS_MAP[groupName];
                const hasGroupChanges = groupFields.some(field => 
                    JSON.stringify(current[field]) !== JSON.stringify(initial[field])
                );

                if (hasGroupChanges) {
                    const groupChanges = {
                        field: groupName,
                        oldValues: {},
                        newValues: {},
                        relatedFields: groupFields
                    };

                    groupFields.forEach(field => {
                        groupChanges.oldValues[field] = initial[field];
                        groupChanges.newValues[field] = current[field];
                    });

                    changes.push(groupChanges);
                }
            } 
            // If field doesn't belong to any group, process normally
            else if (!Object.values(RELATED_FIELDS_MAP).flat().includes(key)) {
                if (typeof currentVal === 'object' && currentVal !== null && !Array.isArray(currentVal)) {
                    if (currentVal instanceof Blob) {
                        if (!initialVal || currentVal.size !== initialVal.size || currentVal.type !== initialVal.type) {
                            changes.push({
                                field: fullPath,
                                oldValue: initialVal,
                                newValue: currentVal,
                            });
                        }
                    } else {
                        compareObjects(currentVal, initialVal || {}, fullPath);
                    }
                } else if (JSON.stringify(currentVal) !== JSON.stringify(initialVal)) {
                    changes.push({
                        field: fullPath,
                        oldValue: initialVal,
                        newValue: currentVal,
                    });
                }
            }
        });
    };

    compareObjects(currentValue, initialValue);
    return changes;
};
const NewPageEditor = ({ iframeURL, setIframeURL, setconfirmationLinkModal, confirmationLinkModal }) => {
    const [iframeIndex, setIframeIndex] = useState(1);
    const { slug } = useSelector(state => state?.slugReducer);
    const dispatch = useDispatch();
    const blockList = useSelector(state => state?.blockReducer)
    const { setAlert } = useAlert();
    const upgradeModal = useSelector(state => state?.upgradeModalReducer);
    const { isProjectLoadedReducer, customizeSeoReducer, bannerModal, profilePicUploadReducer } = useSelector(state => state);
    const profileValue = useSelector(state => state?.customizeProfileReducer);
    const themeValue = useSelector(state => state?.customizeThemeReducer);
    const seoValue = useSelector(state => state?.customizeSeoReducer);
    const showprofilePicModal = useSelector(state => state.profilePicUploadReducer);
    const previewURL = `${protocol}${pageUrl}/${slug}?iframe=1`;
    const [showSaveChanges, setShowSaveChanges] = useState({
        show: false, formId: [], totalChanges: 0, changes: {
            themeValue: [],
            profileValue: [],
            seoValue: []
        },
    });

    const [croppie, setCroppie] = useState(null);
    const [showCroppieModal, setshowCroppieModal] = useState(false);

    const [profileformState, setProfileFormState] = useState({ ...profileValue });
    const [themeformState, setThemeFormState] = useState({ ...themeValue });
    const [seoformState, setSeoFormState] = useState({ ...seoValue });
    const { bannerSelected, linkStyleSelected } = useSelector(state => state);
    const [error, setError] = useState([]);
    const { pathname } = useLocation();
    const { loaded } = isProjectLoadedReducer;
    const history = useHistory();
    const callGetProject = () => {
        getProjects(slug).then((response) => {
            dispatch({ type: "SET_BLOCK_SETTING", payload: response?.blocks });
            setTimeout(() => {
                setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
                setIframeIndex(iframeIndex + 1);
                hideLoader();
            }, 1000);
        }).catch((err) => {
            hideLoader();
        });
    }
    useEffect(() => {
        if (loaded) {
            updateInitialStates();
        }
    }, [loaded]);
    // useEffect(() => {
    //     setTimeout(() => {
    //         setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
    //     }, 1500);
    // }, [blockList]);
    const submitAllChanges = (e,preview= false) => {
        e && e?.preventDefault();

        const valueMapping = {
            profileValue: profileValue,
            themeValue: themeValue,
            seoValue: seoValue,
        };

        let formValues = {
            project: slug,
            tab: [],
            profile_style: bannerSelected,
            link_style: linkStyleSelected,
        };

        showSaveChanges.formId.forEach(id => {
            switch (id) {
                case 'fontValue':
                    if (pro) {
                        formValues = { ...formValues, ...valueMapping[id], tab: [...formValues?.tab, 'custom_fonts'] };
                    } else {
                        return toast.error("Font is not Allowed on this Plan");
                    }
                    break;
                case 'socialValue':
                    let socials = {};
                    valueMapping[id].forEach(val => {
                        socials[val.name] = val.value;
                    });
                    formValues = { ...formValues, socials: JSON.stringify(socials), tab: [...formValues?.tab, 'socials'] };
                    break;
                case 'themeValue':
                    formValues = { ...formValues, ...valueMapping[id], tab: [...formValues?.tab, 'theme'], banner: themeValue?.selected };
                    break;
                case 'profileValue':
                    formValues = { ...formValues, ...valueMapping[id], tab: [...formValues?.tab, 'profile'] };
                    break;
                case 'seoValue':
                    if (!showSaveChanges?.changes?.seoValue?.some(change => change?.field === 'imageData')) {
                        const { imageData, ...seoFields } = valueMapping[id];
                        formValues = { ...formValues, ...seoFields, tab: [...formValues?.tab, 'seo']};
                    } else {
                        formValues = { ...formValues, ...valueMapping[id], tab: [...formValues?.tab, 'seo'] };
                    }
                    break;
            }
        });

        handleSubmitAll(formValues);
        setShowSaveChanges({ show: false, formId: [] });
    }
    const updateInitialStates = () => {
        setProfileFormState({ ...profileValue });
        setThemeFormState({ ...themeValue });
        setSeoFormState({ ...seoValue });
    };
    const handleSubmitAll = (formValues) => {
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

        const formdata = new FormData();
        formValues.tab.forEach((tab, index) => {
            formdata.append(`tab[${index}]`, tab);
        });
        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                if (key != 'tab' && formValues[key] !== null && formValues[key] !== undefined)
                    formdata.append(key, formValues[key]);
            }
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        const apiName = "update";

        fetch(apiUrl + "/project/" + apiName, requestOptions)
            .then(async (res) => {
                let newPathname = pathname;
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setAlert({ type: 'success', title: `${response?.status_message}` });
                    // toast.success(response?.status_message);
                    setIframeURL("");
                    if (formValues?.tab == "profile") {
                        if (formValues?.link == slug) {
                            newPathname = newPathname.replace(slug, formValues?.link)
                            window.history.replaceState(null, 'Page Editor', newPathname)
                        } else {
                            dispatch({ type: "SET_SLUG", payload: { slug: response?.data?.slug } })
                            newPathname = newPathname.replace(slug, response?.data?.slug)
                            history.replace(newPathname)
                        }
                    }
                    setSeoFormState((prevState) => ({
                        ...prevState,
                        isNewImage: false,
                    }));
                    setProfileFormState((prevState) => ({
                        ...prevState,
                        isNewImage: false,
                    }));
                    updateInitialStates()
                    setTimeout(() => {
                        setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
                    }, 500);
                    hideLoader()
                } else {
                    let errorList = [];
                    Object.values(response?.errors || {}).forEach(errorArray => {
                        errorList = [...errorList, ...errorArray];
                    });
                    if (errorList.length !== 0) {
                        errorList?.map((err) => {
                            toast.error(err);
                        });
                    } else {
                        toast.error(response?.status_message)
                    }
                    hideLoader();
                }
            }).catch((err) => {
                hideLoader();
            });
    };

    const revertChanges = () => {
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: profileformState })
        dispatch({ type: "SET_CUSTOMIZE_THEME", payload: themeformState })
        dispatch({ type: "SET_CUSTOMIZE_SEO", payload: seoformState })
        setShowSaveChanges({
            show: false,
            formId: [],
            changes: {
                themeValue: [],
                profileValue: [],
                seoValue: []
            },
            totalChanges: 0
        });
        setIframeIndex(iframeIndex + 1)
        setIframeURL(previewURL);
    }
    const handleFormChanges = (formType, currentValue, initialValue) => {
        const changedFields = getChangedFields(currentValue, initialValue);

        setShowSaveChanges(prevState => {
            const otherFormChanges = prevState?.changes && Object.values(prevState?.changes)
                .filter((_, key) => key !== formType)
                .flat();

            const newState = {
                ...prevState,
                show: changedFields?.length > 0 || otherFormChanges?.length > 0,
                formId: changedFields?.length > 0
                    ? prevState?.formId?.includes(formType)
                        ? prevState?.formId
                        : [...prevState?.formId, formType]
                    : prevState?.formId?.filter(id => id !== formType),
                changes: {
                    ...prevState.changes,
                    [formType]: changedFields
                }
            };

            newState.totalChanges = Object.values(newState?.changes)
                .flat()
                .length;

            return newState;
        });
    };
    const debouncedUpdatePreview = debounce((changes, updatePreviewURL) => {
        updatePreviewURL(changes);
    }, 800);

    useEffect(() => {
        if (showSaveChanges?.show && showSaveChanges?.totalChanges > 0) {
            const themeChanges = showSaveChanges?.changes?.themeValue?.reduce((acc, val) => {
                if (typeof val?.newValues === "object" && val?.newValues !== null) {
                    if (val?.field === "banner_group") {
                        // Extract `editLink` value and store it in `banner` key
                        acc["banner"] = val.newValues?.editLink || "";
                    } else {
                        // Store all other key-value pairs normally
                        Object.entries(val.newValues).forEach(([key, value]) => {
                            acc[key] = value;
                        });
                    }
                } else {
                    acc[val?.field] = val?.newValue;
                }
                return acc;
            }, {});
    
            const profileChanges = showSaveChanges?.changes?.profileValue?.reduce((acc, val) => {
                acc[val?.field] = val?.newValue;
                return acc;
            }, {});
    
            const changes = { ...themeChanges, ...profileChanges };
            debouncedUpdatePreview(changes, updatePreviewURL);
        }else {
            setIframeIndex(iframeIndex + 1);
            setIframeURL(previewURL);
        }
    },[showSaveChanges?.changes])
    const updatePreviewURL = (changes) => {
        const queryParams = new URLSearchParams();
        console.log("changes asdasdas",changes)
        Object.keys(changes).forEach(key => {
            if (typeof changes[key] === "object") {
                queryParams.append(key, JSON.stringify(changes[key])); // Convert object to JSON string
            } else {
                queryParams.append(key, changes[key]);
            }
        });
        setIframeIndex(iframeIndex + 1)
        setIframeURL(`${protocol}${pageUrl}/${slug}?iframe=1&changes=1&${queryParams.toString()}`);
    };
    const croppieClose = () => {
        showLoader()
        setshowCroppieModal(false);
        setCroppie(null)
        // croppie_image = { url: "", id: null };

        dispatch({ type: "RESET_PROFILE_PIC_MODAL" });
        dispatch({ type: "RESET_EDITLINK_PROFILE_PIC_MODAL" });

        setTimeout(() => {
            hideLoader()
        }, 300);

    }
    const SkeletonForTabs = () => {
        const arr = Array.from({ length: 10 }, () => <Skeleton width={200} height={150} className='mb-3' />)
        return arr
    }
    const handleImageChange = (tabName, fieldName, imageData) => {
        setChangedImages(prev => ({
            ...prev,
            [tabName]: {
                ...(prev[tabName] || {}),
                [fieldName]: {
                    blob: imageData.blob,
                    changed: true
                }
            }
        }));
    };
    return (
        <div id="wrapper">
            <PageHeader />
            <div className="page-editor-main">
                <NewSideBar />
                <section className='editor-section'>
                    <SaveChanges from={'mobile'} showSaveChanges={showSaveChanges} revertChanges={revertChanges} submitAllChanges={submitAllChanges}/>
                    <div className='main-content'>
                        <Tabs
                            defaultActiveKey="content"
                            id="justify-tab-example"
                            className="mb-3 up-in-toggle main-btn-header page-editor-tabs btnTxtDark border-less"
                        >
                            {error && <ErrorMessage error={error} />}
                            <Tab className='product-nav-link' eventKey="content"
                                title="Content">
                                <Content callGetProject={callGetProject} iframeURL={iframeURL} setIframeURL={setIframeURL} setIframeIndex={setIframeIndex} setconfirmationLinkModal={setconfirmationLinkModal} confirmationLinkModal={confirmationLinkModal} />
                            </Tab>
                            <Tab className='product-nav-link' eventKey="design"
                                title="Design">
                                {loaded ?
                                    <Design showSaveChanges={showSaveChanges} handleFormChanges={handleFormChanges} />
                                    :
                                    <>
                                        <div className='page-editor-heading'>
                                            Theme
                                        </div>
                                        {SkeletonForTabs}
                                    </>
                                }
                            </Tab>
                            <Tab className='product-nav-link' eventKey="profile"
                                title="Profile">
                                {loaded ?
                                    <Profile setShowSaveChanges={setShowSaveChanges} handleFormChanges={handleFormChanges} />
                                    :
                                    <>
                                        <div className='page-editor-heading'>
                                            Details
                                        </div>
                                        {SkeletonForTabs}
                                    </>
                                }
                            </Tab>
                            <Tab className='product-nav-link' eventKey="options" title="Options">
                                {loaded ? <Options showSaveChanges={showSaveChanges} handleFormChanges={handleFormChanges} setIframeIndex={setIframeIndex} setIframeURL={setIframeURL}/> : <>
                                    <div className='page-editor-heading'>
                                        SEO
                                    </div>
                                    {SkeletonForTabs}
                                </>}
                            </Tab>
                        </Tabs>
                    </div>
                    <div className='new-editor-iframe'>
                        <iframe src={iframeURL} width={"100%"} height={"91%"} key={iframeIndex} frameborder="0"></iframe>
                        <SaveChanges from={'desktop'} showSaveChanges={showSaveChanges} revertChanges={revertChanges} submitAllChanges={submitAllChanges}/>
                    </div>
                </section>
            </div>
            <ProfilePicUploadModal showprofilePicModal={showprofilePicModal?.show} to={showprofilePicModal?.to} croppieClose={croppieClose} handleHide={() => {}} from="profile" />

            {upgradeModal?.show && <Teammoadal {...upgradeModal} />}
        </div>
    )
}

export default NewPageEditor