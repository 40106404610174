import React from 'react'

const SaveChanges = ({showSaveChanges, revertChanges, submitAllChanges, from}) => {
  return (
        showSaveChanges?.show && showSaveChanges?.formId?.length > 0 &&
        <div className={`show-save-changes show-save-changes-${from}`}>
            <span className='btn-ededed fs-12px'>You have {showSaveChanges?.totalChanges} un-published {showSaveChanges?.totalChanges === 1 ? 'change' : 'changes'}</span>
            <button className='btn-gray ml-10-u' onClick={revertChanges}>Revert Changes</button>
            <button className='blue-btn ml-10-u' onClick={submitAllChanges}>Publish Changes</button>
        </div>
  )
}

export default SaveChanges