import React, { Component } from "react";
import iro from "@jaames/iro";
import { hideLoader, showLoader } from "../loader";

class IroCircularColorPicker extends React.Component {
    typingTimer;
    doneTypingInterval = 500;
    
    destroyColorPicker = () => {
        if (this.colorPicker) {
            // Remove all event listeners
            this.colorPicker.off("color:change");
            // Remove the color picker element from DOM
            if (this.el && this.el.firstChild) {
                this.el.removeChild(this.el.firstChild);
            }
            this.colorPicker = null;
        }
    }

    initializeColorPicker = () => {
        // Destroy existing instance first
        this.destroyColorPicker();

        const { props } = this;
        const _this = this;
        
        // Create new color picker instance
        if (this.el) {
            this.colorPicker = new iro.ColorPicker(this.el, {
                width: props.width,
                color: props.color,
                layout: props.layout,
                layoutDirection: props.layoutDirection
            });
            
            // Handle color changes
            this.colorPicker.on("color:change", color => {
                const hexValue = color.hexString;
                
                // Update input field and label
                $(`#hex_code-${props?.themeType}`).val(hexValue);
                $(`#rgb_code-${props?.themeType}`).val(hexValue);
                $(`#hex_code-${props?.themeType}-label`).css("backgroundColor", hexValue);
            });
            
        }
    }

    componentDidMount() {
        this.initializeColorPicker();
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.themeType !== this.props.themeType || prevProps?.color !== this.props.color) {
            this.initializeColorPicker();
        }
    }

    render() {
        return (
            <>
                <div ref={el => (this.el = el)} className="mx-auto" />
                <input 
                    type="hidden" 
                    id={`rgb_code-${this.props?.themeType}`} 
                    className="" 
                    value={this.props?.color} 
                    name="rgb_code" 
                    required="required"
                />
            </>
        );
    }
}

export default IroCircularColorPicker;