import { Modal } from "react-bootstrap"
import ModalErrors from "../../../errors/modal-errors"
import spotifyIcon from "../../../../assets/img/spotify-sync.svg";
import { useEffect, useState } from "react";

const CreateSpotifyWidgetModal =
    ({ show, handleHide, returnIcon, handleSubmit, slug, error, spotifyModal, setspotifyModal }) => {

        const handleChange = (e) => {
            setValue({ ...value, [e.target.name]: e.target.value })
        };
        const [value, setValue] = useState({
            title: null,
            show_type: 'artists',
            no_of_spotifys: null,
            connect: 1
        });

        useEffect(() => {
            if (spotifyModal?.id) {
                setValue({ ...value, title: spotifyModal?.name, no_of_spotifys: spotifyModal?.details?.no_of_spotifys, show_type: spotifyModal?.details?.show_type })
            }
        }, [spotifyModal])


        return (
            <Modal centered show={show} onHide={!spotifyModal?.show ? handleHide : () => {
                setspotifyModal({
                    ...spotifyModal,
                    show: !spotifyModal?.show,
                })
            }} id="createSpotifyWidgetModal">
                <div class="modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header gap-2 justify-content-start">
                            <img src={spotifyIcon} width="30px" alt="" />
                            <span class="font-weight-500 fs-14px">Spotify</span>
                        </div>

                        <div class="modal-body">

                            <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e, value, spotifyModal?.show ? spotifyModal?.id : "spotify", true, "spotify")
                            }}>
                                <div class="notification-container"></div>


                                <div class="form-group">
                                    <label><small>Title</small></label>
                                    <input type="text" class={`zaapGlobal-input`} onChange={handleChange} value={value?.title} name="title" required="required" placeholder="My Spotify" />
                                </div>
                                <div class="form-group mt-3">
                                    <label><small>Show my:</small></label>
                                    <div class={`zaapGlobal-input cursor-pointer`} id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        Top <span className="text-capitalize">{value?.show_type}</span>
                                    </div>
                                    <ul class="dropdown-menu zaap-dropdown-menu" aria-labelledby="table-action-dropdown">
                                        <li onClick={() => setValue({...value, show_type:'artists'})}>
                                            <a class="dropdown-item gray-hover justify-content-start gap-2">
                                                <span>Top Artists</span>
                                            </a>
                                        </li>
                                        <li onClick={() => setValue({...value, show_type:'tracks'})}>
                                            <a class="dropdown-item gray-hover justify-content-start gap-2">
                                                <span>Top Tracks </span>
                                            </a>
                                        </li>
                                        <li onClick={() => setValue({...value, show_type:'albums '})}>
                                            <a class="dropdown-item gray-hover justify-content-start gap-2">
                                                <span>Top Albums </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div class="form-group mt-2">
                                    <label><small>Number</small></label>
                                    <input min={0} type="number" class={`zaapGlobal-input`} onChange={handleChange} value={value?.no_of_spotifys} name="no_of_spotifys" required="required" placeholder="5" />
                                </div> */}


                                <div class="text-center mt-4 d-flex">
                                    <button type="button" onClick={!spotifyModal?.show ? handleHide : () => {
                                        setspotifyModal({
                                            ...spotifyModal,
                                            show: !spotifyModal?.show,
                                        })
                                    }} class="save-btn-u close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <span class="me-2">Cancel</span>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" class="new-primary-btn m-0">
                                        Save Changes
                                        <small class="primary-badge">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </Modal>
        )

    }
export default CreateSpotifyWidgetModal