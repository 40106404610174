import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loader, { showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";

const SpotifyCallBack = () => {
    const history = useHistory()
    const params = new URLSearchParams(window.location.search);

    const sendRequest = (values) => {
        if (localStorage.getItem("sendRequest")) return true
        localStorage.setItem("sendRequest", true)
        const formData = new FormData();

        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                formData.append(key, values[key]);
            }
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };
        fetch(apiUrl + `/project/block/${values?.end_point ? "update" : "create"}`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                localStorage.removeItem("sendRequest")
// console.log("response", response);
                if (response?.status_code == 200) {
                    toast.success(response?.status_message);
                } else {
                    toast.error(response?.status_message);
                }
                history.push(localStorage.last_activity)
            })
            .catch((err) => {
                toast.error(err);
                history.push(localStorage.last_activity)
            })
    }

    useEffect(() => {
        showLoader()
        
        if (params.get("code")) {
            const spotify_values = {
                ...JSON.parse(localStorage?.spotify_values),
                code: params.getAll("code").toString(),
                callback: 1
            }
            delete spotify_values["connect"]
                // localStorage.removeItem("");
            sendRequest(spotify_values)
        }

    }, [])
    return (
        <Loader />
    )
}
export default SpotifyCallBack