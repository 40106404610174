import React, { useEffect, useState } from 'react'
import infoIcon from "../../assets/img/info-black.svg"
import rightArrow from "../../assets/img/plain-right-arrow.svg"
import Skeleton from 'react-loading-skeleton'
import MapChart from '../../utils/analytics/MapChart'
import { useSelector } from 'react-redux'
import { apiUrl } from '../../utils/url'
import CommunityFooter from '../community/footer'
import ViewMoreModal from './view-more-modal'
import FilterDropdowns from './filter-dropdowns'
import CustomTooltip from '../common/CustomTooltip'

const AnalyticsSession = ({ topCountries, statsloading, activity, setactivity, date, dateFilters,statsData , handleFilterSelect, getStats, loading }) => {
    let params = [];
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [countryData, setCountryData] = useState({});
    const [sessionsloading, setSessionsLoading] = useState(true);
    const [showModal, setShowModal] = useState({});
    const [filter, setFilter] = useState({
        page:1,
        count: localStorage?.last_activities_count ?? 10
    });
    useEffect(() => {
        if (topCountries?.length > 0) {
            const data = topCountries.reduce((acc, country) => {
                const opacity = country.percent / 100;
                acc[country.source.toUpperCase()] = `rgba(15, 185, 228, ${opacity})`;
                return acc;
            }, {});

            setCountryData(data);
        }
    }, [topCountries]);
    useEffect(() => {
        params = [];
        if (slug) {
            // for (const key in filter) {
            //     if (Object.hasOwnProperty.call(filter, key)) {
            //         if (filter[key])
            //             params.push(`${[key]}=${filter[key]}`)
            //     }
            // };
            if (filter?.page ){
                callActivites()
            }else{
                setSessionsLoading(false);
            }
        }
    },[filter]);
    const callActivites = async () => {
        setSessionsLoading(true);
        let queryParams = `?from_date=${date?.from_date}&to_date=${date?.to_date}`;
        for (const key in filter) {
            if (Object.hasOwnProperty.call(filter, key)) {
                if (filter[key])
                    queryParams += `&${key}=${filter[key]}`;
            }
        };
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/project/statistics/getActivityLog/get/${slug}${queryParams}`, {
            headers: myHeaders
        })
        const response = await res.json();
        setSessionsLoading(false);
        if (response?.status_code === 200) {
            setactivity(response?.data)
        }else {
            console.log(response)
        }
    };
    return (
        <>
            <div className="multiple-analytics-sections analytics-sessions">
                {(statsloading) || (topCountries?.length > 0 && !statsloading) ?
                    <div className="analytics-sections analytics-session-country">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Country</span>
                            <span>
                                <CustomTooltip message={[{ title: "Country", msg: "The geographic location of your visitors" }]}>
                                    <img src={infoIcon} alt="" className='cursor-pointer' />
                                </CustomTooltip>
                            </span>
                        </div>

                        {topCountries && topCountries.length > 0 ? (
                            <div className="analytics-sections-items-main">
                                {topCountries.map((val, index) => (
                                    <div className="analytics-sections-item analytics-progress" key={index}>
                                        <div style={{ width: val?.percent + '%' }} className="d-flex align-items-center analytics-progress-main gap-1">
                                        {(() => {
                                            try {
                                            return (
                                                <img
                                                src={require(`../../assets/img/flags/${val?.source ?? 'Unknown'}.png`)}
                                                width="22px"
                                                alt=""
                                            />
                                            );
                                            } catch (error) {
                                            return <img src={require(`../../assets/img/flags/Unknown.png`)} alt="" width="22px"/>;
                                            }
                                        })()}
                                            <span className='text-nowrap'>{val?.country_name}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                            <span>{val?.count}</span>
                                            <span className="box-ededed">{val?.percent}%</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="analytics-sections-items-main">
                                {Array(3).fill(null).map((width, idx) => (
                                    <div key={idx} className="analytics-sections-item p-0 analytics-progress">
                                        <div className="w-100">
                                            <Skeleton width={"45%"} height={43}
                                                className="align-middle w-100"
                                                baseColor="rgba(12, 138, 247, 0.14)"
                                                highlightColor="rgba(12, 138, 247, 0.19)"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                        <div className="analytics-progress-end">
                                            0%
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div> : null}
                <div className="analytics-map analytics-sections p-0 overflow-visible">
                    <FilterDropdowns dateFilters={dateFilters} statsData={statsData} isGraphFilter={false} getStats={getStats} loading={loading}/>
                    {
                        statsloading ?
                            <Skeleton className="align-middle w-100" height={"400px"} width={"45%"} /> :
                            <MapChart containerId="defaultWorldMap" countryData={countryData} width={"100%"} height={"100vh"} />
                    }
                </div>
            </div>
            <div className='new-analytics-table-main'>
                <table className='new-analytics-table'>
                    <thead className='new-analytics-table-header'>
                        <tr className='new-analytics-table-tr'>
                            <th className="col-1 text-center ps-0" scope="col">Visitor</th>
                            <th className="col-1" scope="col">Country</th>
                            <th className="col-1" scope="col">TIme</th>
                            <th className="col-1" scope="col">Device</th>
                            <th className="col-4" scope="col">Referrer</th>
                            <th className="col-1 text-center" scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody className='new-analytics-table-body'>

                        {statsloading || sessionsloading ? Array(10).fill(null).map((width, idx) => (
                            <tr className='new-analytics-table-tr' key={idx}>
                                <td>
                                    <Skeleton width={25} height={30}
                                        className="align-middle w-100"  
                                        borderRadius="0.5rem"
                                        duration={4}
                                    />
                                </td>
                                <td>
                                    <Skeleton width={100} height={30}
                                        className="align-middle w-100"
                                        borderRadius="0.5rem"
                                        duration={4}
                                    />
                                </td>
                                <td>
                                    <Skeleton width={53} height={30}
                                        className="align-middle w-100"  
                                        borderRadius="0.5rem"
                                        duration={4}
                                    />
                                </td>
                                <td>
                                    <Skeleton width={410} height={30}
                                        className="align-middle w-100"
                                        borderRadius="0.5rem"
                                        duration={4}
                                    />
                                </td>
                                <td>
                                    <Skeleton width={58} height={30}
                                        className="align-middle w-100"  
                                        borderRadius="0.5rem"
                                        duration={4}
                                    />
                                </td>
                                <td>
                                    <Skeleton width={"15%"} height={30}
                                        className="align-middle w-100"
                                        borderRadius="0.5rem"
                                        duration={4}
                                    />
                                </td>
                            </tr>
                        )) :
                            activity?.data?.map((val, index) => (
                                <tr className='new-analytics-table-tr ' key={index}>
                                    <td className='text-center ps-0'>{val?.id}</td>
                                    <td>
                                        <div className='d-flex align-items-center gap-1'>
                                            <span>
                                            {(() => {
                                                try {
                                                return (
                                                    <img src={require(`../../assets/img/flags/${val?.country_code?.toLowerCase() ?? 'Unknown'}.png`)} width="22px" alt="" />
                                                );
                                                } catch (error) {
                                                return <img src={require(`../../assets/img/flags/Unknown.png`)} alt="" width="22px"/>;
                                                }
                                            })()}
                                            </span>
                                            <span>
                                                {val?.country_name ?? 'Unknown'}
                                            </span>
                                        </div>
                                    </td>
                                    <td>{val?.updated_at_humans}</td>
                                    <td>
                                        <div className='d-flex align-items-center gap-1'>
                                            <span>
                                                <img src={require(`../../assets/img/${val?.device_type?.toLowerCase()}-icon.svg`)} alt="" srcset="" />
                                            </span>
                                            <span className='text-capitalize'>
                                                {val?.device_type}
                                            </span>
                                        </div>
                                    </td>
                                    <td className='analytics-referrer'>{val?.referrer ?? 'Direct'}</td>
                                    <td className='d-flex justify-content-center'>
                                        <button className='dark-btn' onClick={() => {setShowModal({val:val,show:true})}}>
                                            <span>
                                                More
                                            </span>
                                            <span>
                                                <img src={rightArrow} width={"9px"} alt="" />
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className='new-analytics-section-footer'>
                    <div class="section-footer d-flex align-items-center justify-content-between">
                        <CommunityFooter loading={loading} from={"last_activities"} filter={filter} setfilter={setFilter} total={activity?.total} last_page={activity?.last_page} current_page={activity?.current_page}/>
                    </div>
                </div>
            </div>
            {<ViewMoreModal show={showModal} setShow={setShowModal}/>}
        </>
    )
}

export default AnalyticsSession