import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap"
import returnIcon from "../../assets/img/modals/return.svg";
import plusIcon from "../../assets/img/thin-plus-icon.svg";
import { hideLoader, showLoader } from "../loader";
import ErrorMessage from "../errors/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "../../contexts/alertContext";
import { apiUrl } from "../../utils/url";

export const ReusableModal = ({ icon, show, setShow, title, fields, endPoint, buttonText, data = {}, submiCallback=null }) => {
    const [formValues, setFormValues] = useState(data);
    const { slug } = useSelector(state => state?.slugReducer);
    const [error, setError] = useState("");
    const { setAlert } = useAlert();
    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
    const handleClose = () => {
        setShow(false);
        setFormValues({});
        setError("");
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData();

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }
        formdata.append("project", slug);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + endPoint, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    submiCallback && submiCallback(response?.data);
                    setAlert({ type: 'success', title: response?.status_message });
                    hideLoader()
                    handleClose()
                }
                else {
                    setAlert({ type: 'error', title: response?.status_message })
                }
                hideLoader()
            }).catch((err) => {
                hideLoader()
                setError(err);
            })

    }
    useEffect(() => {
        if (show && data) {
            setFormValues({ ...data });
        }
    }, [show])
    return (
        <Modal className="new-confirmation-modal" show={show} onHide={handleClose} centered>
            <Modal.Header className="borderless pb-0">
                <Modal.Title className="font-weight-500 fs-22px">
                    {icon && <div style={{width: 30, height: 30}} className="blue-btn p-0 shadow-none">
                        {icon}
                    </div>}
                    <div className="mt-10px text-capitalize">
                        {title}
                    </div>
                    <ErrorMessage error={error} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 font-weight-500 fs-14px mt-20px">
                {fields.map((field, index) => (
                    <div key={index}>
                        {
                            field?.label &&
                            <label className="block mb-1">{field?.label}</label>
                        }
                        <input
                            type={field?.type || "text"}
                            name={field?.name}
                            placeholder={field?.placeholder}
                            value={formValues?.[field?.name] || ""}
                            onChange={handleChange}
                            className="zaapGlobal-input"
                            required
                        />
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer className="borderless justify-content-start gap-3 mt-20px pt-0">
                <button className="close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center fs-12px font-weight-500 m-0" onClick={handleClose}>
                    <span>Cancel</span>
                    <small className="badge bg-light text-dark p-1 ms-2">Esc</small>
                </button>
                <button onClick={handleSubmit} className="new-primary-btn m-0" type="submit" autoFocus>
                    {buttonText ?? 'Save changes'}
                    <small className="primary-badge">
                        <img src={returnIcon} alt="" />
                    </small>
                </button>
            </Modal.Footer>
        </Modal>
    );
};
