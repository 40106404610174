import React, { useRef, useState } from 'react';

const CustomTooltip = (props) => {
    const { children, message, customClass= null, contentClass=null } = props;
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [isVisible, setIsVisible] = useState(false);
    const [arrowPosition, setArrowPosition] = useState({ top: 0, left: 0 });
    const [arrowDirection, setArrowDirection] = useState("top");
    const tooltipRef = useRef(null);

    const showTooltip = (e) => {
        const tooltipWidth = tooltipRef?.current.offsetWidth ?? 345;
        const tooltipHeight = tooltipRef.current?.offsetHeight || 100;
        const padding = 10;
        
        let x = e.clientX;
        let y = e.clientY;
        let arrowLeft, arrowTop;
        let direction;
        // Horizontal positioning
        if (x + tooltipWidth + padding > window.innerWidth) {
            x = x - tooltipWidth - padding;
            arrowLeft = tooltipWidth - 10;
            direction = "right"; 
        } else {
            x = x + padding;
            arrowLeft = -10;
            direction = "left"; 
        }

        // Vertical positioning
        if (y + tooltipHeight + padding > window.innerHeight) {
            y = y - tooltipHeight;
            arrowTop = tooltipHeight - 10;
        } else {
            y = y + padding;
            arrowTop = 20;
        }

        setPosition({ top: y, left: x });
        setArrowPosition({ top: arrowTop, left: arrowLeft });
        setArrowDirection(direction);
        setIsVisible(true);
    };

    const hideTooltip = () => {
        setArrowPosition({top: 0, left: 0})
        setPosition({top: 0, left: 0})
        setIsVisible(false)
    }

    return (
        <div
            className={`project-tooltip ${customClass}`}
            onMouseMove={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}
            {/* {isVisible && ( */}
                <div
                    className={`project-tooltip-content ${contentClass}`}
                    ref={tooltipRef}
                    style={{
                        top: `${position.top}px`,
                        left: `${position.left}px`,
                        position: 'fixed'
                    }}
                >
                    <div 
                        className={`project-tooltip-content-arrow ${
                            arrowDirection  === "left" ? "project-tooltip-content-arrow-left" :
                            "project-tooltip-content-arrow-right"
                        }`}
                        style={{
                            top: `${arrowPosition.top}px`,
                            left: `${arrowPosition.left}px`
                        }}
                    />
                    {message?.map((val, index) => (
                        <div className="project-tooltip-content-main" key={index}>
                            {val?.title && <div className="heading">{val?.title}</div>}
                            <div className="d-flex align-items-center gap-2 justify-content-start">
                                {val?.icon && <div>
                                    <img src={val?.icon} alt="" />
                                </div>}
                                <div className="msg">{val?.msg}</div>
                            </div>
                        </div>
                    ))}
                </div>
            {/* )} */}
        </div>
    );
};

export default CustomTooltip;
